import React from 'react'
import MuiButton from '../../../../UI/MUI/Button/Button'
import { SxProps } from '@mui/material'
import { BaseComponentProps, ButtonVariants } from '@/types/UI'
import { useRouter } from 'next/navigation'

interface GetAQuoteBtnProps extends BaseComponentProps {
  variant: ButtonVariants
  id: string
  styleOverRides?: SxProps
}

const GetAQuoteNoImpactCreditCta: React.FC<GetAQuoteBtnProps> = ({
  variant,
  id,
  styleOverRides,
  zutoUrl
}) => {
  const router = useRouter()

  const handleButtonClick = () => {
    router.push(`${zutoUrl}/apply/`)
  }

  return (
    <MuiButton
      text="Get a quote"
      id={id}
      variant={variant}
      subtext="No impact on your credit score"
      minWidth="288px"
      styleOverRides={{ ...styleOverRides }}
      onClick={handleButtonClick}
    />
  )
}

export default GetAQuoteNoImpactCreditCta
