'use client'

import { ElementContainer, SectionWrapper } from '@/UI/StyledElements/Wrappers'
import { Typography } from '@mui/material'
import React from 'react'
import TrustpilotCustomerReviews from '../TrustpilotWidgets/TrustpilotCustomerReviews'

const CustomerReviews: React.FC = () => {
  return (
    <SectionWrapper id="customer-reviews" sx={{ minHeight: '316px' }}>
      <ElementContainer
        sx={{
          paddingLeft: {
            xl: 0
          },
          paddingRight: {
            xl: 0
          },
          paddingTop: 0
        }}
      >
        <Typography variant="h2" align="center" sx={{ marginBottom: 6 }}>
          Customer reviews
        </Typography>
        <TrustpilotCustomerReviews id="tag_homepage-trustpilot-widget-slider" />
      </ElementContainer>
    </SectionWrapper>
  )
}

export default CustomerReviews
