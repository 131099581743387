'use client'

import { styled } from '@mui/material'
import USP from '../../../../UI/MUI/USP/USP'
import { dFlexCenter } from '@/Theme/UtilityStyles'

export const UspWrapper = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  textAlign: 'left',
  display: 'flex',
  padding: `0 ${theme.spacing(3)}`,
  [theme.breakpoints.up('mdplus')]: {
    flexWrap: 'wrap'
  },
  [theme.breakpoints.up('lg')]: {
    ...dFlexCenter,
    flexDirection: 'row',
    textAlign: 'center'
  }
}))

const USPs: React.FC = () => {
  return (
    <UspWrapper>
      <USP text="Rated excellent on Trustpilot" />
      <USP text="Get a finance decision in 60 seconds" />
      <USP text="Browse thousands of cars from trusted dealers" />
    </UspWrapper>
  )
}

export default USPs
