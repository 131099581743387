'use client'

import { styled } from "@mui/material"
import { layoutMaxWidth, dFlexCenter } from "@/Theme/UtilityStyles"

interface ElementContainerProps {
    dFlex?: boolean
    noPadding?: boolean
}
  
export const ElementContainer = styled('div')<ElementContainerProps>(({ theme, dFlex, noPadding }) => ({
    ...layoutMaxWidth,
    margin: '0 auto',
    padding: noPadding ? 0 : `${theme.spacing(6)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
    ...(dFlex && {
        ...dFlexCenter
    })
}))

export const SectionWrapper = styled('section')(({ theme }) => ({
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('lg')]: {
        marginBottom: theme.spacing(7)
    }
}))
