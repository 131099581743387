'use client'

import React from 'react'
import { SectionWrapper } from '@/UI/StyledElements/Wrappers'
import { Typography } from '@mui/material'
import ThreeColImageHeadingText from '@/UI/Layout/ThreeColImageHeadingText'
import { ElementContainer } from '@/UI/StyledElements/Wrappers'

const threeColImageHeadingData = [
  {
    imageSrc: 'https://cdn.zuto.cloud/assets/images/homepage/couple-and-laptop.png',
    imageAlt: 'alt',
    heading: '1. Apply online',
    text: "Use our easy online application to tell us a bit about yourself and how much you'd like to borrow. We'll give you a quick decision and one of our team will be in touch to talk you through your options."
  },
  {
    imageSrc: 'https://cdn.zuto.cloud/assets/images/homepage/cars.png',
    imageAlt: 'alt',
    heading: '2. Pick your car',
    text: "Now for the fun part. Whether you've already set your sights on a new car or you want to browse from our network of verified dealers, we'll run a free vehicle and history check on any car you like."
  },
  {
    imageSrc: 'https://cdn.zuto.cloud/assets/images/homepage/handing-key.png',
    imageAlt: 'alt',
    heading: '3. Get on the road',
    text: "Once the final checks are completed and you're happy with the details, we'll take care of everything to make buying your new car as easy as possible. You simply need to collect the car and we'll handle the payment with the dealer."
  }
]

const CarFinanceWithZuto: React.FC = () => {
  return (
    <SectionWrapper id="car-finance-is-quick-with-zuto">
      <ElementContainer
        sx={{
          paddingLeft: {
            xl: 0
          },
          paddingRight: {
            xl: 0
          }
        }}
      >
        <Typography variant="h2" textAlign="center">
          Car finance is quick, easy, and completely transparent with Zuto
        </Typography>
        <Typography variant="body1" textAlign="center" mb={7}>
          Here&apos;s how to arrange car finance in three simple steps with Zuto.
        </Typography>
        <ThreeColImageHeadingText data={threeColImageHeadingData} />
      </ElementContainer>
    </SectionWrapper>
  )
}

export default CarFinanceWithZuto
