'use client'

import { colors } from '@/Theme/LegacyTheme'
import { ElementContainer } from '@/UI/StyledElements/Wrappers'
import { Typography } from '@mui/material'
import CarFinanceCalc from '../../Shared/CarFinanceCalculator/CarFinanceCalc'
import './CalculatorV1.css'

const CalculatorWithHeading: React.FC = () => {
  return (
    <ElementContainer
      sx={{
        paddingTop: {
          xs: 0
        },
        paddingBottom: {
          xs: 5
        }
      }}
    >
      <Typography
        variant="h2"
        align="center"
        color={colors.black}
        mt={{ xs: 5, md: 7 }}
        sx={{
          lineHeight: {
            xs: '32px',
            sm: '30px'
          }
        }}
      >
        Use our car finance calculator
      </Typography>
      <CarFinanceCalc variant="V1" minHeight="100px" />
    </ElementContainer>
  )
}

export default CalculatorWithHeading
