'use client'

import { Typography, styled } from '@mui/material'
import Theme from '@/Theme/LegacyTheme'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ElementContainer } from '@/UI/StyledElements/Wrappers'
import USPs from '../../Shared/USPs/USPs'
import RepExample from '../../Shared/RepRates/RepExample'
import Image from 'next/image'
import GetAQuoteNoImpactCreditCta from '../../Shared/CTAs/GetAQuoteNoImpactCredit'
import { dFlexCenter } from '@/Theme/UtilityStyles'
import HomepageLogos from './HomepageLogos'
import { BaseComponentProps } from '@/types/UI'

export const HomepageWrapper = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.up('lg')]: {
    marginBottom: theme.spacing(7)
  },
  '& .hero-image': {
    marginTop: '72px',
    order: '-1',
    width: '236px',
    height: '125px',
    [theme.breakpoints.up('md')]: {
      width: '387px',
      height: '204px'
    },
    [theme.breakpoints.up('lg')]: {
      order: 0,
      marginTop: 0,
      width: '490px',
      height: '259px'
    }
  }
}))

const HomepageHeader = ({ zutoUrl }: BaseComponentProps) => {
  const isMobile = useMediaQuery(Theme.breakpoints.down('lg'))

  return (
    <HomepageWrapper
      id="homepage-header"
      sx={{
        ...dFlexCenter,
        flexDirection: 'column',
        backgroundImage: 'linear-gradient(#c0e5f9,#fff 125px)',
        mb: {
          xs: 0
        }
      }}
    >
      <Typography
        variant="h1"
        align="center"
        sx={{
          paddingTop: {
            lg: '65px'
          },
          fontSize: {
            xs: '32px',
            sm: '50px'
          },
          mb: {
            xs: '24px',
            lg: 3
          },
          maxWidth: {
            xs: '250px',
            sm: '400px',
            lg: '100%'
          },
          lineHeight: {
            xs: '36px',
            sm: '50px'
          },
          marginTop: {
            xs: 5,
            lg: 0
          }
        }}
      >
        Car finance made simple.
      </Typography>
      <USPs />
      <Image
        src="https://cdn.zuto.cloud/assets/images/homepage/desktop/ford-fiesta.png"
        alt="Ford Fiesta"
        width={isMobile ? 236 : 490}
        height={isMobile ? 125 : 260}
        className="hero-image"
      />
      <GetAQuoteNoImpactCreditCta
        variant="contained"
        styleOverRides={{ marginTop: 5 }}
        id="tag_homepage-header-get-a-quote-btn"
        zutoUrl={zutoUrl}
      />
      <ElementContainer
        sx={{
          paddingTop: 0
        }}
      >
        <RepExample
          align="center"
          fontColor="#92908c"
          fontSize={14}
          mt={5}
          styleOverRides={{
            paddingLeft: {
              mdplus: '80px'
            },
            paddingRight: {
              mdplus: '80px'
            }
          }}
        />
        <HomepageLogos />
      </ElementContainer>
    </HomepageWrapper>
  )
}

export default HomepageHeader
