'use client'

import { Grid, styled } from '@mui/material'
import Image from 'next/image'
import { dFlexSpaceEvenly } from '@/Theme/UtilityStyles'
import TrustpilotMini from '../../Shared/TrustpilotWidgets/TrustpilotMini'

const TrustpilotMiniWrapper = styled('div')(() => ({
  minHeight: '156px',
  minWidth: '130px'
}))

const HomepageLogos = () => {
  return (
    <Grid
      container
      sx={{
        ...dFlexSpaceEvenly,
        flexDirection: {
          xs: 'column',
          sm: 'row'
        },
        margin: {
          xs: '51px 0 0 0',
          mdplus: '51px 0 0'
        }
      }}
    >
      <Image
        src="https://cdn.zuto.cloud/assets/images/homepage/bcorp-2018-logo.svg"
        alt="B Corp 2018 Logo"
        width={54}
        height={90}
        loading="eager"
        style={{ marginBottom: '51px' }}
      />
      <Image
        src="https://cdn.zuto.cloud/assets/images/homepage/desktop/autotrader-preferred-partner-logo.svg"
        alt="Autotrader Preferred Partner Logo"
        width={148}
        height={44}
        loading="eager"
        style={{ marginBottom: '51px' }}
      />
      <TrustpilotMiniWrapper>
        <TrustpilotMini id="tag_homepage-trustpilot-mini" style={{ marginBottom: '51px' }} />
      </TrustpilotMiniWrapper>
    </Grid>
  )
}

export default HomepageLogos
