'use client'
import { RepRates } from '../../../../GlobalVariables/RepRates'
import { Grid, SxProps, Typography } from '@mui/material'

interface RepExampleProps {
  align?: 'left' | 'right' | 'center'
  fontColor?: string
  fontSize?: number
  mt?: number
  styleOverRides?: SxProps
}

const RepExample = ({
  align = 'left',
  fontColor,
  fontSize = 16,
  mt,
  styleOverRides
}: RepExampleProps) => {
  return (
    <Grid item xs={12} mt={mt} sx={{ ...styleOverRides }}>
      <Typography variant="body1" align={align} color={fontColor} fontSize={fontSize}>
        Zuto is a credit broker, not a lender. Our rates start from {RepRates.startFrom} APR. The
        rate you are offered will depend on your individual circumstances. Representative Example:
        Borrowing {RepRates.borrowAmount} over {RepRates.term} with a representative APR of{' '}
        {RepRates.repAPR}, the amount payable would be {RepRates.amountPayable}, with a total cost
        of credit of {RepRates.totalCostOfCredit} and a total amount payable of{' '}
        {RepRates.totalAmountPayable}.
      </Typography>
    </Grid>
  )
}

export default RepExample
