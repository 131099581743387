'use client'

import { SectionWrapper } from '@/UI/StyledElements/Wrappers'
import TextLeftImageRightLayout from '@/UI/Layout/TextLeftImageRightLayout'
import React from 'react'
import { LenderVariables } from '@/GlobalVariables/Lenders'

const WhyUseCarFinance: React.FC = () => {
  return (
    <SectionWrapper
      id="why-car-finance-is-a-simpler-way-to-buy-your-car"
      sx={{
        marginBottom: {
          xs: 0,
          lg: 4
        }
      }}
    >
      <TextLeftImageRightLayout
        heading={{
          text: 'Why car finance is a simpler way to buy your next car',
          variant: 'h2'
        }}
        paragraphs={[
          'Car finance can help you spread the cost of your new car by splitting out the total cost, plus interest, into regular monthly instalments that suit your budget.',
          `Instead of haggling with a dealer or contacting several lenders, applying with Zuto means we can search our panel of over ${LenderVariables.numberOfLenders} lenders to find you the best finance deal based on your personal circumstances. We'll be on hand to help with all your car financing needs, from the first quote through to finding the right car. `,
          "We also work with a network of trusted car dealers, so you know you're in good hands whether you're buying your first run-around, something for the family, or your dream car."
        ]}
        image={{
          src: 'https://cdn.zuto.cloud/assets/images/homepage/nissan.png',
          alt: 'Nissan Car image',
          width: 585,
          height: 425
        }}
      />
    </SectionWrapper>
  )
}

export default WhyUseCarFinance
