import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { loadTrustpilotScript } from './tpLoader'

interface WidgetProps {
  id: string
  style?: CSSProperties
}

const TrustpilotReviews = ({ id, style }: WidgetProps) => {
  const tpRef = useRef(null)
  const [domLoaded, setDomLoaded] = useState(false)

  const loadTrustpilot = async () => {
    await loadTrustpilotScript()
    await window?.Trustpilot?.loadFromElement(tpRef.current, true)
  }

  useEffect(() => {
    loadTrustpilot()
    setDomLoaded(true)
  }, [])

  return (
    <div style={{ height: '240px' }}>
      {domLoaded && (
        <div
          ref={tpRef}
          style={style}
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="54ad5defc6454f065c28af8b"
          data-businessunit-id="4c5153c900006400050d7636"
          data-style-height="240px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages="en"
          data-text-color="#333333"
          id={id}
          data-testid={id}
        ></div>
      )}
    </div>
  )
}

export default TrustpilotReviews
