'use client'

import React from 'react'
import Image from 'next/image'
import { Typography, styled } from '@mui/material'
import { dFlexCenter } from '@/Theme/UtilityStyles'
import { ElementContainer } from '../StyledElements/Wrappers'
import { colors } from '@/Theme/LegacyTheme'

interface ImageData {
  src: string
  alt: string
  width: number
  height: number
}

interface HeadingData {
  text: string
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
}

interface TextLeftImageRightLayoutProps {
  heading: HeadingData
  paragraphs: string[]
  image: ImageData
}

const TextLeftImageRightContainer = styled('div')(({ theme }) => ({
  ...dFlexCenter,
  flexDirection: 'column',
  backgroundColor: colors.lightGrey,
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    padding: theme.spacing(5)
  },
  '& .left': {
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(5)
    }
  },
  img: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: '100%',
      height: 'auto'
    }
  }
}))

const TextLeftImageRightLayout: React.FC<TextLeftImageRightLayoutProps> = ({
  heading,
  paragraphs,
  image
}) => {
  const { src, alt, width, height } = image
  const { text, variant } = heading

  return (
    <TextLeftImageRightContainer>
      <ElementContainer
        dFlex
        sx={{
          flexDirection: {
            xs: 'column',
            lg: 'row'
          },
          paddingLeft: {
            xs: 3,
            lg: 0
          },
          paddingRight: {
            xs: 3,
            lg: 0
          },
          paddingTop: {
            xs: 5,
            lg: 0
          }
        }}
      >
        <div className="left">
          <Typography variant={variant}>{text}</Typography>
          {paragraphs.map((paragraph, index) => (
            <Typography key={index} variant="body1">
              {paragraph}
            </Typography>
          ))}
        </div>
        <div className="right">
          <Image src={src} alt={alt} width={width} height={height} />
        </div>
      </ElementContainer>
    </TextLeftImageRightContainer>
  )
}

export default TextLeftImageRightLayout
