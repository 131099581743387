'use client'
import Script from 'next/script'

interface ExperimentAssignedProps {
  eventLabel: string
}

const ExperimentAssignedDataLayerEvent = ({ eventLabel }: ExperimentAssignedProps) => {
  const scriptContent = `
  if (!window.dataLayerInitialised) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'experiment_assigned',
      eventModel: {
        event_label: '${eventLabel}',
        event_category: 'Acq-Components'
      }
    });
    window.dataLayerInitialised = true
  }
`
  return (
    <Script
      id="experiment_assigned"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{ __html: scriptContent }}
    />
  )
}

export default ExperimentAssignedDataLayerEvent
