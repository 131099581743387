'use client'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material'
import LoadingSpinner from '@/UI/Loading/LoadingSpinner'
import { fonts } from '@/Theme/LegacyTheme'
import { loadTrustpilotScript } from '../TrustpilotWidgets/tpLoader'

interface CalculateVariant {
  variant: 'V1' | 'V2'
}

interface CalculatorContainerProps {
  variant: CalculateVariant['variant']
}

const CalculatorContainer = styled('div')<CalculatorContainerProps>(({ theme, variant }) => ({
  paddingTop: variant === 'V2' ? theme.spacing(5) : 0,
  minHeight: variant === 'V2' ? 711 : 1170,
  [theme.breakpoints.up('sm')]: {
    minHeight: variant === 'V2' ? 711 : 1000
  },
  [theme.breakpoints.up('mdplus')]: {
    minHeight: variant === 'V2' ? 711 : 720
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: variant === 'V2' ? theme.spacing(7) : 0,
    minHeight: variant === 'V2' ? 741 : 630
  },
  h1: {
    marginTop: 0
  },
  button: {
    padding: 15,
    borderRadius: '1.5rem',
    cursor: 'pointer'
  },
  '& .personalised-quote-btn': {
    fontFamily: fonts.regular
  }
}))

const CarFinanceCalc = ({
  variant = 'V2',
  minHeight = '500px',
  calculatorHeading,
  dataCreditScore,
  hideRepExample,
  lightTrustpilotTheme
}: {
  variant: CalculateVariant['variant']
  minHeight: string
  calculatorHeading?: string
  dataCreditScore?: string
  hideRepExample?: string
  lightTrustpilotTheme?: boolean
}) => {
  const [loading, setLoading] = useState(true)
  const reactScript =
    'https://cdnjs.cloudflare.com/ajax/libs/react/17.0.2/umd/react.production.min.js'

  // Using useEffect here to prevent Hydration Errors. Ref: https://nextjs.org/docs/messages/react-hydration-error
  useEffect(() => {
    const container = document.getElementById('calculator-container')
    const calculatorJs =
      process.env.NODE_ENV === 'production'
        ? 'https://cdn.zuto.cloud/acquisition-budget-calculator/main.js'
        : 'https://cdn.uat.zuto.cloud/acquisition-budget-calculator/main.js'

    if (!container) {
      console.error('Container not found')
      return
    }

    const div = document.createElement('div')
    div.id = 'react-budget-calculator'

    div.setAttribute('data-experiment', `calculator${variant}`)
    if (lightTrustpilotTheme) {
      div.setAttribute('data-light-trustpilot-theme', 'true')
    }
    if (calculatorHeading) {
      div.setAttribute('data-calculator-heading', `${calculatorHeading}`)
    }
    if (dataCreditScore) {
      div.setAttribute('data-creditscore', `${dataCreditScore}`)
    }
    if (hideRepExample) {
      div.setAttribute('data-hide-rep-example', 'true')
    }
    div.className = 'div-block'
    container.appendChild(div)

    const loadScript = (src: string, callback: () => void) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = src
      script.defer = true
      script.onload = callback
      document.body.appendChild(script)
    }

    // Load React and ReactDOM scripts first
    loadScript(reactScript, () => {
      loadScript(
        'https://cdnjs.cloudflare.com/ajax/libs/react-dom/17.0.2/umd/react-dom.production.min.js',
        () => {
          // After React and ReactDOM are loaded:
          // Load calculator stylesheet
          const stylesheetLink = document.createElement('link')
          stylesheetLink.rel = 'stylesheet'
          stylesheetLink.href =
            'https://cdn.zuto.cloud/acquisition-budget-calculator/main.css?version=230323'
          document.head.appendChild(stylesheetLink)

          // Load calculator script
          loadScript(calculatorJs, () => {
            // load Trustpilot script last
            variant === 'V2' && loadTrustpilotScript()
          })
        }
      )
    })

    setLoading(false)

    return () => {
      const cleanup = (scriptId: string) => {
        const scriptElement = document.querySelector(`script[src="${scriptId}"]`)
        if (scriptElement) {
          document.body.removeChild(scriptElement)
        }
      }
      cleanup('https://cdnjs.cloudflare.com/ajax/libs/react/17.0.2/umd/react.production.min.js')
      cleanup(
        'https://cdnjs.cloudflare.com/ajax/libs/react-dom/17.0.2/umd/react-dom.production.min.js'
      )
      cleanup(calculatorJs)
    }
  }, [])

  return (
    <>
      <CalculatorContainer id="calculator-container" className={variant} variant={variant}>
        {loading && <LoadingSpinner containerHeight={minHeight} />}
      </CalculatorContainer>
    </>
  )
}

export default CarFinanceCalc
