'use client'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'
import InfoIcon from '@mui/icons-material/Info'
import { colors } from '../src/Theme/LegacyTheme'
import { Typography } from '@mui/material'
import { LenderVariables } from '@/GlobalVariables/Lenders'
import { SingleCardDataProps } from '@/types/UI'

export const whyChooseZutoCardData: SingleCardDataProps[] = [
  {
    title: {
      text: 'Get a decision within minutes',
      variant: 'h3'
    },
    description:
      "Once you've filled in our online application, we'll give you a quick decision so you know if we can help you, and how much you'll be able to borrow.",
    icon: <ThumbUpOffAltIcon sx={{ color: colors.darkGreen, fontSize: 30 }} />
  },
  {
    title: {
      text: 'Find the right finance',
      variant: 'h3'
    },
    description: `At Zuto, we'll search our panel of lenders to find the best deal for you. We have access to over ${LenderVariables.numberOfProducts} products including some that aren't available anywhere else.`,
    icon: <WorkspacePremiumIcon sx={{ color: colors.darkGreen, fontSize: 30 }} />
  },
  {
    title: {
      text: 'Save time and hassle',
      variant: 'h3'
    },
    description:
      "Our experts will guide you through the car buying process, and we'll help you with the paperwork and negotiations with the dealer.",
    icon: <InfoIcon sx={{ color: colors.darkGreen, fontSize: 30 }} />
  }
]

export const typesOfFinanceAvailable = {
  introText:
    'There are many different types of car finance available, and each has its own advantages. The best type of car finance for you will depend on your individual circumstances and needs. The three main types of financing available for your next car purchase at Zuto are:',
  items: [
    {
      subHeading: 'HP car finance',
      subHeadingLink: 'https://www.zuto.com/hp-car-finance/',
      content: (
        <Typography variant="body1">
          Also known as hire purchase, this allows you to spread the cost of a car instead of paying
          in one lump sum. Once you&apos;ve arranged a hire purchase contract, you can pay for your
          car in regular monthly instalments over a set period. Once you make the final payment, the
          vehicle is officially yours.
        </Typography>
      )
    },
    {
      subHeading: 'PCP car finance',
      subHeadingLink: 'https://www.zuto.com/pcp-car-finance/',
      content: (
        <Typography variant="body1">
          A personal contract purchase agreement also allows you to make regular monthly payments
          over a set period. But instead of paying off the value of the car, you pay off the
          depreciation. At the end of the contract, you can hand the vehicle back or make a final
          balloon payment to take ownership of it.
        </Typography>
      )
    },
    {
      subHeading: 'Personal loans',
      content: (
        <Typography variant="body1">
          If you buy with a personal loan, you&apos;ll borrow money and then use this to pay for
          your chosen car outright and you&apos;ll own it from day one. You&apos;ll then need to
          keep up with the monthly repayments to your chosen lender.
        </Typography>
      )
    }
  ]
}
