'use client'

import React, { MouseEventHandler } from 'react'
import Button from '@mui/material/Button'
import styled from '@mui/material/styles/styled'
import { fonts } from 'src/Theme/LegacyTheme'
import { SxProps, Typography } from '@mui/material'
import { ButtonVariants } from '@/types/UI'

interface MuiButtonProps {
  text: string
  id: string
  subtext?: string
  variant?: ButtonVariants
  minWidth?: string
  styleOverRides?: SxProps
  onClick: MouseEventHandler<HTMLButtonElement>
}

const MuiStyledButton = styled(Button)(() => ({
  borderRadius: '300px',
  '& .text-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    span: {
      lineHeight: 1
    },
    '& .main-text': {
      fontWeight: 600,
      fontFamily: fonts.bold
    }
  }
}))

const MuiButton: React.FC<MuiButtonProps> = ({
  text,
  id,
  subtext,
  variant = 'outlined',
  minWidth,
  styleOverRides,
  onClick
}) => {
  return (
    <>
      <MuiStyledButton
        variant={variant}
        sx={{ minWidth: minWidth, ...styleOverRides }}
        onClick={onClick}
        id={id}
      >
        <div className="text-wrapper">
          <span className="main-text">{text}</span>{' '}
          {subtext && (
            <Typography variant="body1" mb={0} fontSize="14px">
              {subtext}
            </Typography>
          )}
        </div>
      </MuiStyledButton>
    </>
  )
}

export default MuiButton
