import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { loadTrustpilotScript } from './tpLoader'
interface WidgetProps {
  id: string
  style?: CSSProperties
}

const TrustpilotMini = ({ id, style }: WidgetProps) => {
  const tpRef = useRef(null)
  const [domLoaded, setDomLoaded] = useState(false)

  const loadTrustpilot = async () => {
    await loadTrustpilotScript()
    await window?.Trustpilot?.loadFromElement(tpRef.current, true)
  }

  useEffect(() => {
    loadTrustpilot()
    setDomLoaded(true)
  }, [])

  return (
    <div style={{ height: '85px' }}>
      {domLoaded && (
        <div
          ref={tpRef}
          className="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="53aa8807dec7e10d38f59f32"
          data-businessunit-id="4c5153c900006400050d7636"
          data-style-height="85px"
          data-style-width="130px"
          data-theme="light"
          style={style}
          id={id}
          data-testid={id}
        ></div>
      )}
    </div>
  )
}

export default TrustpilotMini
