'use client'

import { Typography, styled, Link, SxProps } from '@mui/material'
import React, { ReactNode } from 'react'

interface SectionItem {
  subHeading: string
  subHeadingLink?: string
  content: ReactNode
}

interface SectionData {
  introText: string
  items: SectionItem[]
}

interface HeadingWithListProps {
  title: string
  sectionData: SectionData
  // Optional: For CLS.
  minHeight?: string
  styleOverrides?: SxProps
}

const HeadingWithListBlock = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(7),
  ul: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20
    }
  },
  li: {
    marginBottom: theme.spacing(3),
    '&:last-of-type': {
      marginBottom: 0
    },
    '&::marker': {
      color: theme.palette.primary.dark
    }
  },
  '& .intro-text': {
    marginBottom: theme.spacing(4)
  }
}))

const HeadingWithList: React.FC<HeadingWithListProps> = ({
  title,
  sectionData,
  minHeight,
  styleOverrides
}) => {
  return (
    <HeadingWithListBlock sx={{ minHeight: minHeight, ...styleOverrides }}>
      <Typography variant="h2" align="center">
        {title}
      </Typography>
      <div>
        <Typography variant="body1" className="intro-text">
          {sectionData.introText}
        </Typography>
        <ul>
          {sectionData.items.map((item, index) => (
            <li key={index}>
              <span>
                {item.subHeadingLink ? (
                  <Link href={item.subHeadingLink} role="link">
                    {item.subHeading}
                  </Link>
                ) : (
                  <span className="text-bold">{item.subHeading}</span>
                )}{' '}
                {item.content}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </HeadingWithListBlock>
  )
}

export default HeadingWithList
