'use client'
import React from 'react'
import { SxProps, styled } from '@mui/material'
import { dFlexCenter } from '../../Theme/UtilityStyles'
import { colors } from '../../Theme/LegacyTheme'

interface GreenCircleProps {
  variant?: 'small'
}

const GreenCircleIcon = styled('div')<GreenCircleProps>(({ theme, variant }) => ({
  ...dFlexCenter,
  backgroundColor: colors.lightGreen,
  minWidth: variant === 'small' ? theme.spacing(5) : theme.spacing(7),
  minHeight: variant === 'small' ? theme.spacing(5) : theme.spacing(7),
  borderRadius: '100%',
  marginBottom: 0,
  [theme.breakpoints.up('mdplus')]: {
    marginBottom: theme.spacing(2)
  },
  '& svg': {
    fontSize: variant === 'small' ? 20 : 30,
    color: colors.darkGreen
  }
}))

const GreenCircle = ({
  icon,
  variant,
  styleOverrides
}: {
  icon: React.ReactNode
  variant?: 'small'
  styleOverrides?: SxProps
}) => {
  return (
    <GreenCircleIcon data-testid="green-circle" variant={variant} sx={{ ...styleOverrides }}>
      {icon}
    </GreenCircleIcon>
  )
}

export default GreenCircle
