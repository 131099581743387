'use client'

import { Grid, Typography } from '@mui/material'
import React from 'react'
import { dFlexCenter } from '@/Theme/UtilityStyles'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import { colors } from '@/Theme/LegacyTheme'

const tickIcon = <DoneIcon sx={{ color: colors.darkGreen }} />
const crossIcon = <CloseIcon sx={{ color: colors.errorRed }} />
const optionalText = 'Optional'

const headings = [
  'Finance Features',
  'Hire Purchase (HP)',
  'Personal Contract Purchase (PCP)',
  'Personal Loan'
]
const features = [
  {
    name: 'Requires initial deposit',
    values: [optionalText, optionalText, crossIcon]
  },
  {
    name: 'Car is yours at the end of the agreement',
    values: [tickIcon, optionalText, tickIcon]
  },
  {
    name: 'Fixed monthly payments',
    values: [tickIcon, tickIcon, tickIcon]
  },
  {
    name: 'Avoid (final) balloon payment',
    values: [tickIcon, crossIcon, tickIcon]
  },
  {
    name: 'Avoid excess mileage charge',
    values: [tickIcon, crossIcon, tickIcon]
  },
  {
    name: 'Secured against an asset (e.g. a car)',
    values: [tickIcon, tickIcon, crossIcon]
  },
  {
    name: 'Support with vehicle issues',
    values: [tickIcon, tickIcon, crossIcon]
  }
]

const FinanceFeaturesGrid: React.FC = () => {
  return (
    <Grid
      container
      sx={{
        border: 'none',
        padding: {
          xs: '0 15px',
          md: 0
        }
      }}
    >
      {/* Headings */}
      {headings.map((heading, index) => (
        <Grid
          key={index}
          item
          xs={3}
          sx={{
            border: '1px solid  #d6d6d6',
            textAlign: 'center',
            padding: {
              xs: '12px',
              md: '20px'
            },
            ...dFlexCenter
          }}
        >
          <Typography variant="body1" sx={{ mb: 0, fontWeight: 600 }}>
            {heading}
          </Typography>
        </Grid>
      ))}

      {/* Features */}
      {features.map((feature, featureIndex) => (
        <React.Fragment key={featureIndex}>
          <Grid
            item
            xs={3}
            sx={{
              border: '1px solid  #d6d6d6',
              textAlign: 'center',
              padding: {
                xs: '5px',
                sm: '10px',
                md: '12px'
              }
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: {
                  xs: 'center'
                },
                fontSize: {
                  xs: '12px',
                  md: '16px'
                },
                marginBottom: 0
              }}
            >
              {feature.name}
            </Typography>
          </Grid>
          {feature.values.map((value, valueIndex) => (
            <Grid
              key={valueIndex}
              item
              xs={3}
              sx={{
                border: '1px solid  #d6d6d6',
                padding: {
                  xs: '5px',
                  sm: '10px',
                  md: '12px'
                },
                ...dFlexCenter
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  textAlign: {
                    xs: 'center'
                  },
                  fontSize: {
                    xs: '12px',
                    md: '16px'
                  },
                  marginBottom: 0
                }}
              >
                {value}
              </Typography>
            </Grid>
          ))}
        </React.Fragment>
      ))}
    </Grid>
  )
}

export default FinanceFeaturesGrid
