'use client'

import { Grid, Typography, styled } from '@mui/material'
import Image from 'next/image'

interface ImageData {
  imageSrc: string
  imageAlt: string
  heading: string
  text: string
}

interface Props {
  data: ImageData[]
}

const ThreeColImageHeadingTextGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('mdplus')]: {
    '& .MuiGrid-grid-mdplus-4': {
      paddingRight: theme.spacing(5),
      '&:last-of-type': {
        paddingRight: 0
      }
    }
  }
}))

const ThreeColImageHeadingText: React.FC<Props> = ({ data }) => {
  return (
    <ThreeColImageHeadingTextGrid container>
      {data.map((item, index) => (
        <Grid key={index} item xs={12} mdplus={4}>
          <Image src={item.imageSrc} alt={item.imageAlt} width={178} height={187} />
          <Typography variant="h3">{item.heading}</Typography>
          <Typography variant="body1">{item.text}</Typography>
        </Grid>
      ))}
    </ThreeColImageHeadingTextGrid>
  )
}

export default ThreeColImageHeadingText
