'use client'

import React from 'react'
import { ElementContainer, SectionWrapper } from '@/UI/StyledElements/Wrappers'
import HeadingWithList from '@/UI/Paragraph/HeadingWithList'
import { Grid, Typography } from '@mui/material'
import { typesOfFinanceAvailable } from '@app/HomepageContent'
import FinanceFeaturesGrid from './FinanceFeaturesGrid'

const TypesOfFinanceAvailable: React.FC = () => {
  return (
    <SectionWrapper id="types-of-finance-available" sx={{ marginTop: 7 }}>
      <ElementContainer
        noPadding
        sx={{
          paddingLeft: {
            xl: 0
          },
          paddingRight: {
            xl: 0
          }
        }}
      >
        <Grid container justifyContent="center" display="flex">
          <Grid item xs={9}>
            <HeadingWithList
              title="Types of finance available"
              sectionData={typesOfFinanceAvailable}
              styleOverrides={{ mb: 4 }}
            />
            <Typography variant="body1">
              Whichever option you choose, you&apos;ll borrow money from a lender to pay for your
              car, then repay the lender in regular monthly instalments to cover the money you
              borrowed, plus interest.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 7 }}>
              Not sure which option is right for you? Check out our handy comparison chart.
            </Typography>
          </Grid>
        </Grid>
        <FinanceFeaturesGrid />
      </ElementContainer>
    </SectionWrapper>
  )
}

export default TypesOfFinanceAvailable
