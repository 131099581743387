'use client'

import React from 'react'
import { Grid, Typography } from '@mui/material'
import GreenCircle from '../../Icons/GreenCircle'
import DoneIcon from '@mui/icons-material/Done'

interface USPProps {
  text: string
}

const USP: React.FC<USPProps> = ({ text }) => (
  <Grid
    item
    xs={12}
    md={4}
    display="flex"
    flexDirection="row"
    mr={{ lg: 4 }}
    alignItems="center"
    mb={{ xs: 2 }}
    data-testid="usp-grid-item"
  >
    <GreenCircle
      icon={<DoneIcon data-testid="done-icon" />}
      variant="small"
      styleOverrides={{ mr: 2, mb: { xs: 0 } }}
    />
    <Typography variant="body1" mb={0} textAlign="left" fontSize={{ xs: '14px', md: '16px' }}>
      {text}
    </Typography>
  </Grid>
)

export default USP
