'use client'
import React from 'react'
import { styled } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { dFlexCenter } from '../../Theme/UtilityStyles'

interface LoadingSpinnerProps {
  containerHeight?: string
}

const LoadingSpinnerContainer = styled('div')(() => ({
  ...dFlexCenter
}))

/* Pass containerHeight in to center loading spinner */
const LoadingSpinner = ({ containerHeight }: LoadingSpinnerProps) => {
  return (
    <LoadingSpinnerContainer
      sx={{ height: containerHeight }}
      data-testid="loading-spinner-container"
    >
      <CircularProgress data-testid="circular-progress" />
    </LoadingSpinnerContainer>
  )
}

export default LoadingSpinner
