'use client'
import { Grid, Typography } from '@mui/material'
import { whyChooseZutoCardData } from '../../../../../app/HomepageContent'
import GetAQuoteNoImpactCreditCta from '../../Shared/CTAs/GetAQuoteNoImpactCredit'
import CardGrid from '@/UI/MUI/Card/CardGrid'
import { layoutMaxWidth } from '@/Theme/UtilityStyles'
import { BaseComponentProps } from '@/types/UI'

const WhyChooseZuto = ({ zutoUrl }: BaseComponentProps) => {
  return (
    <Grid
      component="section"
      id="why-choose-zuto"
      container
      alignContent="center"
      justifyContent="center"
      mb={{ xs: 5, lg: 7 }}
      sx={{ ...layoutMaxWidth, padding: { xs: '0 20px  !important', xl: '0 !important' } }}
    >
      <Typography variant="h2">Why choose Zuto?</Typography>
      <CardGrid cardsData={whyChooseZutoCardData} xsCols={12} mdCols={4} withBoxShadow />
      <GetAQuoteNoImpactCreditCta
        variant="outlined"
        id="tag_homepage-why-choose-zuto-get-a-quote-cta"
        zutoUrl={zutoUrl}
      />
    </Grid>
  )
}

export default WhyChooseZuto
